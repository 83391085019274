

.noticeboard-bg-image{
    background-image: url('../images/kids.jpg');
    background-size: cover;
    padding: 5px;
    width: 40%;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.noticeboard-bg-image-mobile{
    background-image: url('../images/kids.jpg');
    background-size: cover;
    padding: 5px;
    width: 100%;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.noticeboard-bg-other{
    background-color: rgba(255,255,255,0.7);
    text-align: center;
}

.notice-board{
    font-style: oblique;
}

