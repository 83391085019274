.puf-logo{
    padding-left: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right:20px;
    height: 100px;
    width: 110px;
    
}

.centerimage{
    align-content: left;
    display: flex;
    flex-direction: row;
}

.puf{
    padding-top: 5px;
    padding-left: 5px;
    height: 700px;
    width: 60%;
    
}

.slogan{
    padding-left:5px;
}