.title-header{
    background-color: lightblue;
    margin-left: 5px;
   
}
.puf-header{
    text-align: center;
}

.center-image{
    max-width: 100%;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.puf-slogan{
    align-items: center;
}

.puf-slogan-para{
    font-size: 10px;
    text-align: center;
}